import React, { FC, useState, useRef } from 'react';
import Slider from 'react-slick';
import GatsbyImage from 'common/GatsbyImage';

import 'styles/common/slick-styles.scss';
import './CustomCarousel.scss';
import Button from 'common/Button';
import Icon from 'common/IconCustom';
import Section from 'common/Section';
import { graphql } from 'gatsby';
import { ICustomCarouselProps } from './models';

const CustomCarousel: FC<ICustomCarouselProps> = ({
  description = '',
  slides,
  className = '',
  waiSettings,
  color,
}) => {
  const sliderRef = useRef<Slider | null>(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesLength = slides.length;

  const handleNext = () => {
    sliderRef.current?.slickNext();
  };

  const handlePrevious = () => {
    sliderRef.current?.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (_, next) => setCurrentSlide(next),
    afterChange: (current) => setCurrentSlide(current),
  };

  return slidesLength ? (
    <Section name="custom-carousel" intro={description} color={color} classes={className}>
      <>
        <Slider ref={sliderRef} {...settings}>
          {slides
            ? slides.map(({ properties }) => (
                <div key={properties.image[0].properties.imageAlt} className="slider__item">
                  <GatsbyImage
                    image={properties.image[0].properties.image}
                    alt={properties.image[0].properties.imageAlt}
                    role="presentation"
                  />
                </div>
              ))
            : null}
        </Slider>
        <div className="slider__info">
          <div className="slider__info-text">
            <span className="slider__info-count">
              <span className="slider__info-count--selected">{`0${currentSlide + 1}`}</span>
              {`/0${slidesLength}`}
            </span>
            <p className="slider__info-description">
              {slides[currentSlide].properties.description}
            </p>
          </div>
          <div className="slider__info-navigation">
            <Button
              type="button"
              variant="icon"
              data-testid="custom-carousel-navigator-prev"
              classes="custom-carousel-section-navigator"
              ariaLabel={waiSettings?.ariaPrev}
              disabled={currentSlide === 0}
              clickHandler={handlePrevious}
            >
              <Icon icon="chevron-left" />
            </Button>
            <Button
              type="button"
              variant="icon"
              data-testid="custom-carousel-navigator-next"
              classes="custom-carousel-section-navigator"
              ariaLabel={waiSettings?.ariaNext}
              disabled={currentSlide + 1 === slidesLength}
              clickHandler={handleNext}
            >
              <Icon icon="chevron-right" />
            </Button>
          </div>
        </div>
      </>
    </Section>
  ) : null;
};

export const query = graphql`
  fragment FragmentCustomCarousel on TCarousel {
    properties {
      description
      color {
        label
      }
      slides {
        properties {
          description
          image {
            properties {
              image {
                ...FragmentGatsbyProps
                gatsbyImage {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              imageAlt
            }
          }
        }
      }
      showInMenu
      anchorName
      anchorTitle
    }
    structure
  }
`;
export default CustomCarousel;
