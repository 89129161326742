import React, { FC, useCallback } from 'react';
import { graphql } from 'gatsby';
import classNames from 'classnames';

import { DangerouslySetInnerHtml } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import { gtmService } from 'utils/gtmService';

import { IRetailersBlockProps } from './model';

import './RetailersBlock.scss';

const RetailersBlock: FC<IRetailersBlockProps> = ({ title, retailers, variant }) => {
  const clickHandler = useCallback(
    (name) => () => {
      gtmService.emitRetailerClick(name);
    },
    []
  );

  return (
    <div
      className={classNames('retailer-block', {
        [`retailer-block--${variant}`]: variant,
      })}
    >
      {title ? <DangerouslySetInnerHtml html={title} className="retailer-block__title" /> : null}

      <div className="retailer-block__links">
        {retailers.map(
          ({
            properties: {
              image: { logo, ariaLabel, imageAlt },
              link,
            },
          }) => (
            <Button
              to={link?.[0]?.queryString ? link?.[0]?.url + link[0].queryString : link?.[0]?.url}
              target={link?.[0]?.target}
              key={imageAlt + ariaLabel}
              variant="icon"
              ariaLabel={ariaLabel}
              classes="retailer-block__link"
              clickHandler={clickHandler(link?.[0]?.name)}
            >
              <GatsbyImage
                image={logo}
                objectFit="contain"
                className="retailer-block__image"
                alt={imageAlt}
              />
            </Button>
          )
        )}
      </div>
    </div>
  );
};

export const query = graphql`
  fragment RetailersFragment on TRetailerButton {
    properties {
      image {
        logo {
          ...FragmentGatsbyProps
          gatsbyImage {
            childImageSharp {
              fluid(maxWidth: 150) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        imageAlt
        ariaLabel
      }
      link {
        queryString
        url
        target
        name
      }
    }
    structure
  }
`;

export default RetailersBlock;
