import React, { FC } from 'react';
import { graphql } from 'gatsby';

import RecommendedProductsCarousel from 'common/RecommendedProductsCarousel';
import RecommendedProductsList from 'common/RecommendedProductsList';
import { IArticleRecommendedProducts } from './model';

const ArticleRecommendedProducts: FC<IArticleRecommendedProducts> = ({
  description,
  recommendedProducts,
  usePriceSpider,
  isCarousel,
  waiSettings,
  isEanProductId,
}) =>
  isCarousel ? (
    <RecommendedProductsCarousel
      description={description}
      recommendedProducts={recommendedProducts}
      usePriceSpider={usePriceSpider}
      ariaNext={waiSettings.ariaNext}
      ariaPrev={waiSettings.ariaPrev}
      isEanProductId={isEanProductId}
    />
  ) : (
    <RecommendedProductsList
      description={description}
      recommendedProducts={recommendedProducts}
      usePriceSpider={usePriceSpider}
      isEanProductId={isEanProductId}
    />
  );

export const query = graphql`
  fragment FragmentArticleRecommendedProducts on TRecommendedProducts {
    properties {
      description
    }
    structure
  }
`;

export default ArticleRecommendedProducts;
