import React, { FC } from 'react';
import classNames from 'classnames';
import { graphql } from 'gatsby';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import { ITextBlockProps } from './model';

import '@fontsource/montserrat/latin-700.css';
import './TextBlock.scss';

const TextBlock: FC<ITextBlockProps> = ({ text, className = '' }) => (
  <div
    className={classNames('text-block', {
      [`${className}`]: className,
    })}
  >
    <DangerouslySetInnerHtml html={text} />
  </div>
);

export const query = graphql`
  fragment FragmentTextBlock on TTextBlock {
    structure
    properties {
      text
    }
  }
`;

export default TextBlock;
