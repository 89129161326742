import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { DangerouslySetInnerHtml } from 'layout';

import GatsbyImage from 'common/GatsbyImage';
import Button from 'common/Button';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { IStep } from './models';
import { DEVIDER } from './constants';

import './Step.scss';

const Step: FC<IStep> = ({
  properties: {
    description,
    link,
    image,
    imageAutoHeight,
    descriptionOpener,
    descriptionOpenerLabel,
    descriptionCloser,
    descriptionCloserLabel,
  },
  isHorizontal = false,
}) => {
  const [isActive, setActive] = useState<boolean>(false);
  const { isMobile } = useScreenRecognition();

  const deviderPosition = description.indexOf(DEVIDER);
  const lastTagPosition = description.lastIndexOf('<');
  const additionalDescription = description.slice(deviderPosition, lastTagPosition);
  const shortDescription = description.replace(additionalDescription, '');
  const openCloseText = isActive ? description.replace(DEVIDER, '') : shortDescription;

  const openCloseHandler = () => setActive(!isActive);

  return (
    <div
      className={classNames('step', {
        'step--horizontal': isHorizontal,
        'step--image-auto': imageAutoHeight,
      })}
      data-testid="step"
    >
      {image?.length ? (
        <div
          className={classNames('step__image', {
            'step__image--horizontal': isHorizontal,
          })}
        >
          <GatsbyImage
            image={image[0].properties.image}
            className="step__image-img"
            alt={image[0].properties.imageAlt}
            objectFit={imageAutoHeight ? 'contain' : 'cover'}
          />
        </div>
      ) : null}
      {description ? (
        <div
          className={classNames('step__description', {
            'step__description--horizontal': isHorizontal,
          })}
        >
          <DangerouslySetInnerHtml
            className="step__description-text"
            html={
              deviderPosition !== -1 && !isMobile ? description.replace(DEVIDER, '') : openCloseText
            }
          />
          {deviderPosition !== -1 && isMobile ? (
            <Button
              variant="link"
              classes="step__description-opener"
              iconSuffix={isActive ? 'chevron-up' : 'chevron-down'}
              clickHandler={openCloseHandler}
              ariaLabel={isActive ? descriptionOpenerLabel : descriptionCloserLabel}
            >
              {isActive ? descriptionOpener : descriptionCloser}
            </Button>
          ) : null}

          {link?.length ? (
            <Button
              to={link[0].properties.link[0].url}
              ariaLabel={link[0].properties.ariaLabel}
              variant="secondary-link"
              iconSuffix="chevron-right"
            >
              {link[0].properties.link[0].name}
            </Button>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default Step;
