import React, { FC } from 'react';
import classnames from 'classnames';
import loadable from '@loadable/component';

import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';
import BreadCrumbs from 'common/BreadCrumbs';
import GatsbyImage from 'common/GatsbyImage';
import { IArticleDetailsBannerProps } from './model';
import './ArticleDetailsBanner.scss';

const ShareButtons = loadable(() => import('components/ShareButtons'));

const ArticleDetailsBanner: FC<IArticleDetailsBannerProps> = ({
  image,
  breadCrumbs = [],
  title = '',
  description = '',
  date = '',
  dateSufix = '',
  shareButtons,
  shareTitle,
  isSubNavigationPresent,
  titleSize,
  titleSizeMobile,
  bannerBgColor,
  bannerTheme,
}) => {
  const bannerImage = image?.[0]?.properties;
  const dateInfo = `${date ? `${date}` : ''}${dateSufix ? ` • ${dateSufix}` : ''}`;
  const titleSizeClass = titleSize?.[0] || 'large';
  const titleSizeClassMobile = titleSizeMobile?.[0] || 'large';

  return (
    <div
      className={classnames('article-details-banner', {
        [`article-details-banner--${bannerTheme}`]: bannerTheme,
        'article-details-banner--top-margin': isSubNavigationPresent,
        [`article-details-banner--${bannerBgColor?.label}-bg`]: bannerBgColor?.label,
      })}
    >
      <GatsbyImage
        image={bannerImage?.image}
        alt={bannerImage?.imageAlt}
        className="article-details-banner__image"
        isLazyLoading={false}
        fadeIn={false}
        useFocalPoint
      />
      <div className="article-details-banner__info">
        <div className="article-details-banner__content">
          <BreadCrumbs data={breadCrumbs} />
          {title ? (
            <DangerouslySetInnerHtml
              element="h1"
              className={classnames('article-details-banner__title', {
                [`article-details-banner__title--${titleSizeClass}`]: titleSizeClass,
                [`article-details-banner__title--${titleSizeClassMobile}-m`]: titleSizeClassMobile,
              })}
              html={title}
            />
          ) : null}
          {description ? (
            <DangerouslySetInnerHtml
              className="article-details-banner__description"
              html={description}
            />
          ) : null}
        </div>

        <div className="article-details-banner__extra">
          <div className="article-details-banner__date-info">{dateInfo}</div>
          {shareButtons?.length ? (
            <ShareButtons shareTitle={shareTitle} shareButtons={shareButtons} />
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default ArticleDetailsBanner;
