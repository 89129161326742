import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import Button from 'common/Button';
import GatsbyImage from 'common/GatsbyImage';

import { IBuyNowProps } from './model';

import './BuyNowBlock.scss';

const BuyNowBlock: FC<IBuyNowProps> = ({ title, shops }) => (
  <section className="buy-now">
    {title ? <DangerouslySetInnerHtml html={title} className="buy-now__title" /> : null}
    <div className="buy-now__links">
      {shops?.map(({ properties: { link: [{ url, name, target }] = [{}], image } }) => (
        <Button to={url} ariaLabel={name} target={target} variant="link" key={url}>
          <div className="buy-now__image">
            <GatsbyImage image={image?.[0]?.properties?.image} />
          </div>
          <p className="buy-now__link-name">{name}</p>
        </Button>
      ))}
    </div>
  </section>
);

export default BuyNowBlock;
