import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import ProductCard from 'components/ProductCard';

import { RecommendedProductsListProps } from './model';

import './RecommendedProductsList.scss';

const RecommendedProductsList: FC<RecommendedProductsListProps> = ({
  description,
  recommendedProducts,
  usePriceSpider,
  isEanProductId,
}) => (
  <div className="recommended-products" data-testid="recommended-products">
    {description ? (
      <DangerouslySetInnerHtml className="recommended-products__description" html={description} />
    ) : null}
    <ul className="recommended-products__grid">
      {recommendedProducts?.nodes?.map((product) => (
        <li key={product.link}>
          <ProductCard {...{ ...product, hasChevron: true, usePriceSpider, isEanProductId }} />
        </li>
      ))}
    </ul>
  </div>
);

export default RecommendedProductsList;
