import React, { FC } from 'react';
import classNames from 'classnames';
import StepList from 'components/StepList';

import { IArticleStepList } from './model';
import './ArticleStepList.scss';

const ArticleStepList: FC<IArticleStepList> = ({ properties, className = '' }) => (
  <div
    className={classNames('article-steps-list', {
      [`${className}`]: className,
    })}
  >
    <StepList properties={properties} />
  </div>
);

export default ArticleStepList;
