import React, { FC } from 'react';

import { DangerouslySetInnerHtml } from 'layout';
import ProductCard from 'components/ProductCard';
import BlockCarousel from 'components/BlockCarousel';
import PrevArrow from 'components/PDCarousel/components/prevArrow';
import NextArrow from 'components/PDCarousel/components/nextArrow';
import RetailersBlock from 'common/RetailersBlock';

import { IRecommendedProductsCarouselProps } from './model';

import './RecommendedProductsCarousel.scss';

const RecommendedProductsCarousel: FC<IRecommendedProductsCarouselProps> = ({
  description,
  recommendedProducts,
  ariaNext,
  ariaPrev,
  usePriceSpider,
  isEanProductId,
}) => {
  const settings = {
    dots: true,
    customPaging: () => <span className="btn btn--icon" />,
    prevArrow: <PrevArrow ariaLabel={ariaPrev} />,
    nextArrow: <NextArrow ariaLabel={ariaNext} />,
    responsive: [
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 620,
        settings: {
          className: 'center',
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 460,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="recommended-products-carousel" data-testid="recommended-products-carousel">
      {description ? (
        <DangerouslySetInnerHtml
          className="recommended-products-carousel__description"
          html={description}
        />
      ) : null}
      <BlockCarousel settings={settings}>
        {recommendedProducts?.nodes?.map((product) => (
          <div className="recommended-products-carousel__item" key={product.link}>
            <ProductCard {...{ ...product, usePriceSpider, isEanProductId }} />
            {product.cardRetailers?.[0].properties?.retailers?.length ? (
              <RetailersBlock
                retailers={product.cardRetailers[0].properties.retailers}
                variant="product"
              />
            ) : null}
          </div>
        ))}
      </BlockCarousel>
    </div>
  );
};

export default RecommendedProductsCarousel;
