import React, { FC } from 'react';
import { Container } from 'layout';
import GatsbyImage from 'common/GatsbyImage';
import classNames from 'classnames';
import DangerouslySetInnerHtml from 'common/DangerouslySetInnerHtml';

import { ImageBlockProps } from './models';
import './ImageBlock.scss';

const ImageBlock: FC<ImageBlockProps> = ({ image, description, className = '' }) => {
  const previewImage = image?.[0]?.properties;

  return (
    <Container
      fluid
      className={classNames('article-image-block', {
        [`${className}`]: className,
      })}
    >
      <GatsbyImage
        image={previewImage.image}
        alt={previewImage.imageAlt}
        className="article-image-block__image"
      />
      <DangerouslySetInnerHtml className="article-image-block__text" html={description} />
    </Container>
  );
};

export default ImageBlock;
