import React, { FC, useState } from 'react';
import classNames from 'classnames';
import Accordion from 'react-tiny-accordion';

import StepList from 'components/StepList';
import Button from '../Button';
import DangerouslySetInnerHtml from '../../layout/DangerouslySetInnerHtml';
import Icon from '../IconCustom';
import { ControlledAccordionProps } from './model';
import './ControlledAccordion.scss';

const ControlledAccordion: FC<ControlledAccordionProps> = ({ items }) => {
  const [expandedIndex, setExpandedIndex] = useState<number | null>(null);

  const onChangeIndex = (_, expanded: boolean, selectedIndex: number) => {
    if (expanded) {
      setExpandedIndex(selectedIndex);
    } else {
      setExpandedIndex(null);
    }
  };

  return (
    <Accordion className="accordion" transitionDuration="300" onChange={onChangeIndex}>
      {items?.length
        ? items.map(({ properties: { label, ariaLabel, content, link } }, index) => (
            <div
              className="accordion__item"
              key={label}
              data-header={
                <Button
                  variant="icon"
                  classes={classNames('accordion__question', { link__style: link?.length })}
                  aria-expanded={index === expandedIndex}
                  ariaLabel={ariaLabel}
                  to={link?.[0]?.url}
                >
                  {!link?.length ? (
                    <>
                      <Icon icon="minus" />
                      <Icon icon="plus" />
                    </>
                  ) : null}
                  {label}
                </Button>
              }
            >
              {content?.length && !link?.length ? (
                typeof content === 'string' ? (
                  <DangerouslySetInnerHtml className="accordion__text" html={content} />
                ) : (
                  content.map((item, idx) =>
                    item.properties.text ? (
                      <DangerouslySetInnerHtml
                        key={label + idx}
                        className="accordion__text"
                        html={item.properties.text}
                      />
                    ) : (
                      <StepList key={label + idx} {...item} />
                    )
                  )
                )
              ) : null}
            </div>
          ))
        : null}
    </Accordion>
  );
};

export default ControlledAccordion;
