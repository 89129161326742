import React, { FC } from 'react';

import classNames from 'classnames';
import Button from 'common/Button';

import { DownloadButtonProps } from './models';
import './DownloadButton.scss';

const DownloadButton: FC<DownloadButtonProps> = ({
  properties: { title, ariaLabel, link },
  className = '',
}) => (
  <div
    className={classNames('download-button__wrapper', {
      [`${className}`]: className,
    })}
  >
    <Button
      target="_blank"
      ariaLabel={ariaLabel}
      variant="primary-link"
      to={link.url}
      classes="download-button"
      iconPrefix="download"
      download
    >
      {title}
    </Button>
  </div>
);

export default DownloadButton;
