import React, { FC } from 'react';

const QuoteImage: FC<any> = () => (
  <svg
    width="31px"
    height="24px"
    viewBox="0 0 31 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className="quote-image"
  >
    <title>“</title>
    <g
      id="page-designs"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      opacity="0.299748012"
    >
      <g
        id="article"
        transform="translate(-348.000000, -2730.000000)"
        fill="#5A6C87"
        fillRule="nonzero"
      >
        <g id="Group" transform="translate(348.000000, 2730.000000)">
          <path
            d="M7.2122449,24 C9.32108844,24 11.0503401,23.3351206 12.4,22.0053619 C13.7496599,20.6756032 14.4244898,18.9383378 14.4244898,16.7935657 C14.4244898,15.3351206 14.097619,14.0589812 13.4438776,12.9651475 C12.7901361,11.8713137 11.8727891,11.0455764 10.6918367,10.4879357 L10.6918367,10.4879357 L13.6653061,0 L6.13673469,0 L2.0877551,9.45844504 C1.20204082,11.3887399 0.632653061,12.847185 0.379591837,13.8337802 C0.126530612,14.8203753 0,15.8069705 0,16.7935657 C0,18.9383378 0.674829932,20.6756032 2.0244898,22.0053619 C3.37414966,23.3351206 5.10340136,24 7.2122449,24 Z M23.7877551,24 C25.8965986,24 27.6258503,23.3351206 28.9755102,22.0053619 C30.3251701,20.6756032 31,18.9383378 31,16.7935657 C31,15.3351206 30.6731293,14.0589812 30.0193878,12.9651475 C29.3656463,11.8713137 28.4482993,11.0455764 27.2673469,10.4879357 L27.2673469,10.4879357 L30.2408163,0 L22.7122449,0 L18.6632653,9.45844504 C17.777551,11.3887399 17.2081633,12.847185 16.955102,13.8337802 C16.7020408,14.8203753 16.5755102,15.8069705 16.5755102,16.7935657 C16.5755102,18.9383378 17.2503401,20.6756032 18.6,22.0053619 C19.9496599,23.3351206 21.6789116,24 23.7877551,24 Z"
            id="“"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default QuoteImage;
