import React, { FC } from 'react';

import { Container } from 'layout';
import { IQuoteProps } from '@shared/types/umbraco/compositions/article/quote';
import './Quote.scss';
import GatsbyImage from 'common/GatsbyImage';
import classNames from 'classnames';
import QuoteImage from './QuoteImageComponent';

export interface IQuote extends IQuoteProps {
  className?: string;
}

const Quote: FC<IQuoteProps> = ({ text = '', image, name = '', country = '', className = '' }) => {
  const previewImage = image?.[0]?.properties?.image;

  return (
    <Container
      fluid
      className={classNames('quote-flex-container', {
        [`${className}`]: className,
      })}
    >
      <QuoteImage />
      <div>
        <div className="quote-text">{`${text}"`}</div>
        <div className="quote-user">
          <GatsbyImage className="quote-user__icon" image={previewImage} />
          <div className="quote-user__details">
            <div className="name">{name}</div>
            <div className="country">{country}</div>
          </div>
        </div>
      </div>
    </Container>
  );
};
export default Quote;
